import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Washington",
  "subtitle": "-",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "seattle"
    }}>{`Seattle`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uLJ6oVdXJBwHWA6s6"
        }}>{`Fran’s chocolate`}</a>{` - They sell chocolate trees and gnomes around Christmas. Free sample when you walk in the store.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qDAp9cxsUPRGE2CZA"
        }}>{`MariPili Tapas Bar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tQR5x9idPrFdG8UX7"
        }}>{`Piroshky Piroshky`}</a>{` - Anything with `}<a parentName="li" {...{
          "href": "https://www.tillamook.com/"
        }}>{`Tillamook cheese`}</a>{` is good. The Smoked Salmon Pate was also fantastic.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hWD8w6nrEQyzbsXD6"
        }}>{`Rachel’s Ginger Beer`}</a></li>
    </ul>
    <h4 {...{
      "id": "ice-cream"
    }}>{`Ice Cream`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://saltandstraw.com/"
        }}>{`Salt & Straw`}</a>{` - Technically from Oregon but it's great`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.mollymoon.com/"
        }}>{`Molly Moon's`}</a>{` - Local`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.chihulygardenandglass.com/"
        }}>{`Chihuly Garden & Glass Museum`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.pikeplacemarket.org/"
        }}>{`Pike Place Market`}</a>{` - Lots of cute shops and fresh flowers`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NwfpaNyG4nmKiz17A"
        }}>{`Golden Gardens Park`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XDt32P4cH9AwvE4q9"
        }}>{`Ballard Farmers Market`}</a>{` - Sundays only`}</li>
    </ul>
    <h2 {...{
      "id": "mount-rainier-national-park"
    }}>{`Mount Rainier National Park`}</h2>
    <p>{`Included in the National Park Pass`}</p>
    <ul>
      <li parentName="ul">{`Around 4 hour drive each way from Seattle. A daytrip is long and exhausting but worth it!`}</li>
      <li parentName="ul">{`I recommend checking the blooming status of the `}<a parentName="li" {...{
          "href": "https://www.nps.gov/mora/planyourvisit/wildflower-status.htm"
        }}>{`wildflowers`}</a>{` if going in the summer. It's very pretty!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.wta.org/go-hiking/hikes/skyline-trail-loop"
        }}>{`Skyline Trail Loop`}</a></li>
    </ul>
    <h2 {...{
      "id": "olympic-national-park"
    }}>{`Olympic National Park`}</h2>
    <p>{`Included in the National Park Pass`}</p>
    <ul>
      <li parentName="ul">{`Around 4 hour drive each way from Seattle. A daytrip is long and exhausting but worth it!`}</li>
      <li parentName="ul">{`There are several `}<a parentName="li" {...{
          "href": "https://www.nps.gov/olym/planyourvisit/visitorcenters.htm"
        }}>{`visitor centers`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Unfortunately `}<a parentName="li" {...{
              "href": "https://www.nps.gov/olym/hurricane-ridge-post-fire.htm"
            }}>{`Hurricane Ridge burned down`}</a></li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "sequim"
    }}>{`Sequim`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HwrRZPDvmYpeMSZk8"
        }}>{`B & B Family Lavender Farm`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/jVfWHxdSofA8AN5F7"
        }}>{`Black Bear Diner`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/8ytEFRjLwbrhweUQ7"
            }}>{`Supercharger`}</a>{` at the Holiday Inn`}</li>
          <li parentName="ul">{`The portion sizes were huge especially the pancakes`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "skagit"
    }}>{`Skagit`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tulipfestival.org/"
        }}>{`Tulip Festival`}</a>{` - Usually in April`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.skagitfoodcoop.com/"
        }}>{`Food Co-op`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/RvpnLkxHuWHSZtdR8"
        }}>{`Cairnspring Mills`}</a></li>
    </ul>
    <h2 {...{
      "id": "bow"
    }}>{`Bow`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.taylorshellfishfarms.com/"
        }}>{`Taylor Shellfish Farms`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      